require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("jquery");
require("jquery-ui");
require("flatpickr/dist/flatpickr.css");
require("flatpickr/dist/themes/dark.css");
require('jquery-ui/ui/widgets/sortable');
require.context('../images', true);

import $ from 'jquery';
import 'bootstrap';
import '../stylesheets/application.scss';
import flatpickr from 'flatpickr';
import { Lithuanian } from "flatpickr/dist/l10n/lt.js";
import Splide from '@splidejs/splide';
import "@fortawesome/fontawesome-free/js/all";
import 'select2'
import 'select2/dist/css/select2.css'
import "trix"
import "@rails/actiontext"

// window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

window.getParam = function(name) {
  var url = window.location.search.substring(1);
  var varUrl = url.split('&');
  for (var i = 0; i < varUrl.length; i++){
    var parameter = varUrl[i].split('=');
    if (parameter[0] == name){
        return parameter[1];
    }
  }
}

// document.addEventListener("turbolinks:load", function () {
//   const clientForm = document.querySelector("#new_client");

//   if (clientForm) {
//     clientForm.addEventListener("submit", function (event) {
//       const licensesInput = event.target.querySelector('input[type="file"][name="client[licenses][]"]');

//       if (licensesInput) {
//         licensesInput.value = "";
//       }
//     });
//   }
// });

document.addEventListener('show.bs.modal', event => {
  const config = {
    dropdownParent: '#new-rent-modal',
    width: '100%'
  };
  $('.custom-select').select2(config);
});

document.addEventListener("turbolinks:load", () => {

  if($('.index-carousel').length){
    initializeSplide('.index-carousel', {
      type: 'loop',
      perPage: 1,
      isNavigation: true,
      height: '490px',
      arrows: false,
      cover: true
    })
  }

  if ($('.vehicle-images').length) {
    initializeSplide('.vehicle-images', {
      type: 'loop',
      perPage: 1,
      isNavigation: true,
      arrows: true,
      cover: true,
    })
  }
  

  let vehicles_list = $('.vehicles-list');

  if (vehicles_list.length) {
    initializeSplide('.vehicles-list', {
      type: 'slide',
      perPage: 1,
      perMove: 1,
      isNavigation: true,
      cover: true,
      height: '392px',
      width: '100%',
      breakpoints: {
        640: {
          height: '300px',
        },
        991: {
          height: '392px',
        },
      },
      arrows: true,
      start: 0,
      rewind: true,
    })
  }

  
  
  

  window.date = flatpickr(".datepickerr", {
    allowInput: false,
    locale: Lithuanian,
    disableMobile: true,

  });

  window.date_rent = flatpickr(".datepickerr-rent", {
    allowInput: false,
    allowInvalidPreload: true,
    locale: Lithuanian,
    enableTime: true,
    minuteIncrement: 15,
    defaultHour: 8,
    disableMobile: true,
  });

  $(".images-sort").sortable({
    update: function(e, ui){
      let textValues = $('.sorted-image').map(function() {
        return $(this).attr('id');
      });
      delete(textValues.length);
      delete(textValues.prevObject);
      $.ajax({
        url: $(this).data("url"),
        type: "POST",
        dataType: "json",
        data: {
          images: JSON.stringify(textValues),
          vehicle: $(this).data("vehicle")
        }
      });
    }
  });
});

//testing
document.addEventListener('turbolinks:before-cache', function () {
  destroySplide('.index-carousel');
  destroySplide('.vehicle-images');
  destroySplide('.vehicles-list');
});


function initializeSplide(selector, options) {
  let elements = $(selector);

  if (elements.length) {
    $.each(elements, function (id, element) {
      let splideInstance = new Splide(element, options).mount();
      $(element).data('splideInstance', splideInstance);
    });
  }
}

function destroySplide(selector) {
  let elements = $(selector);

  if (elements.length) {
    $.each(elements, function (id, element) {
      let splideInstance = $(element).data('splideInstance');
      if (splideInstance) {
        splideInstance.destroy();
      }
    });
  }
}